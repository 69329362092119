import './css/contact.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot, faBus, faTrainSubway, faPhone, faEnvelope, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import AOS from 'aos';

function Contact() {
    useEffect(() => {
        AOS.init();
    })
    return (
        <div className='contact'>
            <div className='contact-top'>
                <div className='contact-top-img'></div>
            </div>
            <div className='contact-section1'>
                <div className='contact-section1-1' data-aos="fade-down" data-aos-duration="1500" data-aos-once="true">오시는 길</div>
                <div className='contact-section1-2'>
                    <div className='contact-section1-2-1' >
                        <NaverMap />
                    </div>
                    <div className='contact-section1-2-2' >
                        <div className='contact-section1-2-3'>
                            <div className='conact-section1-2-3-1'>
                                <FontAwesomeIcon icon={faMapLocationDot} />
                            </div>
                            <div className='conact-section1-2-3-2'>
                                <div className='conact-section1-2-3-3'>Address</div>
                                <div className='conact-section1-2-3-4'>서울특별시 서초구 강남대로 221, 5층 514-1호</div>
                            </div>
                        </div>
                        <div className='contact-section1-2-3'>
                            <div className='conact-section1-2-3-1'>
                                <FontAwesomeIcon icon={faBus} />
                            </div>
                            <div className='conact-section1-2-3-2'>
                                <div className='conact-section1-2-3-3'>Bus</div>
                                <div className='conact-section1-2-3-4'>양재역 하차 &gt; 도보 3분</div>
                            </div>
                        </div>
                        <div className='contact-section1-2-3'>
                            <div className='conact-section1-2-3-1'>
                                <FontAwesomeIcon icon={faTrainSubway} />
                            </div>
                            <div className='conact-section1-2-3-2'>
                                <div className='conact-section1-2-3-3'>Train</div>
                                <div className='conact-section1-2-3-4'>양재역 10번출구 &gt; 도보 1분</div>
                            </div>
                        </div>
                        <div className='contact-section1-2-3'>
                            <div className='conact-section1-2-3-1'>
                                <FontAwesomeIcon icon={faPhone} />
                            </div>
                            <div className='conact-section1-2-3-2'>
                                <div className='conact-section1-2-3-3'>Phone</div>
                                <div className='conact-section1-2-3-4'>1599-1873</div>
                            </div>
                        </div>
                        <div className='contact-section1-2-3'>
                            <div className='conact-section1-2-3-1'>
                                <FontAwesomeIcon icon={faPrint} />
                            </div>
                            <div className='conact-section1-2-3-2'>
                                <div className='conact-section1-2-3-3'>Fax</div>
                                <div className='conact-section1-2-3-4'>0504-178-2170</div>
                            </div>
                        </div>
                        <div className='contact-section1-2-3'>
                            <div className='conact-section1-2-3-1'>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className='conact-section1-2-3-2'>
                                <div className='conact-section1-2-3-3'>E-mail</div>
                                <div className='conact-section1-2-3-4'>help@hb.re.kr</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NaverMap = () => {
    useEffect(() => {
      // 네이버 지도 생성
      const map = new window.naver.maps.Map('map', {
        center: new window.naver.maps.LatLng(37.4836, 127.0344), // 서울 좌표
        zoom: 17,
      });
  
      // 마커 생성
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(37.4836, 127.0344),
        map: map,
      });
    }, []);
  
    return <div id="map" className='naver-map'></div>;
  };

export default Contact;