import { configureStore, createSlice } from '@reduxjs/toolkit'


let SelectAbout = createSlice({
    name: 'SelectAbout',
    initialState: { value: 0 },
    reducers: {
        checkSelectAbout(state, action) {
            state.value = action.payload
        }
    }
})

let SelectBusiness = createSlice({
    name: 'SelectBusiness',
    initialState: { value: 0 },
    reducers: {
        checkSelectBusiness(state, action) {
            state.value = action.payload
        }
    }
})

let HeaderType = createSlice({
    name: 'HeaderType',
    initialState: { value: 0 },
    reducers: {
        checkHeaderType(state, action) {
            state.value = action.payload
        }
    }
})

// let HeaderType = createSlice({
//     name: 'HeaderType',
//     initialState: { value: false },
//     reducers: {
//         checkHeaderType(state, action) {
//             state.value = action.payload
//         }
//     }
// })

export let { checkSelectAbout } = SelectAbout.actions
export let { checkSelectBusiness } = SelectBusiness.actions
export let { checkHeaderType } = HeaderType.actions

export default configureStore({
    reducer: {
        SelectAbout: SelectAbout.reducer,
        SelectBusiness: SelectBusiness.reducer,
        HeaderType: HeaderType.reducer,
    }
}) 