import './css/insights.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import data from './newsData';
import Page from './Page';

const maxFirstPage = 10;
let list = []
function Insights() {
    const [page, setPage] = useState(1);
    const targetRef1 = useRef(null);

    const onContent1Click = () => {
        targetRef1.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    useEffect(() => {
        AOS.init();
    })

    list = data.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)
    return (
        <div className='insights'>
            <div className='insights-top'>
                <div className='insights-top-img'></div>
            </div>
            <div className='insights-section1'>
                <div className='insights-section1-1' data-aos="fade-down" data-aos-duration="1500" data-aos-once="true">뉴스</div>
                <div className='insights-section1-2' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100" data-aos-once="true">해피브릿지 새소식을 누구보다 빠르게</div>
            </div>
            <div className='insights-section2'>
                {
                    data.map(function (a, i) {
                        if (i <= 2) {
                            return (
                                <TopItem key={i} item={a} />
                            )
                        }
                    })
                }
            </div>
            <div className='insights-section3'>
                <div className='insights-section3-1' ref={targetRef1}>소식</div>
                {
                    list.map(function (a, i) {
                        return (
                            <NewsItem key={i} item={a} />
                        )
                    })
                }
                <Page initialData={data} page={page} setPage={setPage} maxFirstPage={maxFirstPage} onContent1Click={onContent1Click}/>
            </div>
        </div>
    )
}

function TopItem({ item }) {
    return (
        <div className='insights-section2-1' onClick={() => window.open(`${item.url}`)}>
            <div className='section2-3-1'>{item.type}</div>
            <div className='section2-3-2'>{item.title}</div>
            <div className='section2-3-3'>{item.content}</div>
            <div className='section2-3-4'>{item.date}</div>
        </div>
    )
}

function NewsItem({ item }) {
    const [mouse, setMouse] = useState(false)

    const onMouseEnter = () => {
        setMouse(true)
    }

    const onMouseLeave = () => {
        setMouse(false)
    }

    return (
        <div className='insights-section-3-box' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => window.open(`${item.url}`)}>
            <div className='insights-section-3-img'>
                <img src={`/img/insights/${item.img}`} />
            </div>
            <div className='insights-section3-2'>
                <div className='insights-section3-2-1'>{item.type}</div>
                <div className='insights-section3-2-2'>{item.title}</div>
                <div className='insights-section3-2-3'>{item.content}</div>
                <div className='insights-section3-2-4'>{item.date}</div>

            </div>
            {
                mouse
                    ?
                    <FontAwesomeIcon icon={faArrowRightLong} data-aos="fade-right" data-aos-duration="1200" />
                    :
                    ""
            }
        </div>
    )
}

export default Insights;