const data = [
    {
        type: "News",
        title: "해피브릿지 “국내 클라우드 기반 PG 서비스 구현, 행복한 소통 본격화될 것”",
        content: "사람의 행복은 ‘소통’을 통해 비롯된다. 하지만 코로나19 사태의 장기화는 이러한 소통을 가로막았다. 때문에 디지털 기기를 통한 소통이 불가피해졌지만 이는 아무래도 직접소통에 비하면 한계가 있다.",
        date: "2021. 6. 18. 19:53",
        url: "https://www.donga.com/news/It/article/all/20210618/107508682/1",
        img: "insights36.jpg"
    },
    {
        type: "blog",
        title: "믿을 수 있는 PG사 어떻게 고를까?!",
        content: "오늘은 믿을 수 있는 PG사를 선별하는 방법에 대해서 알려드리려고 합니다! 간편결제 시장이 점점 커지게 되면서 다양한 PG업체들도 생겨나는데요 엄청난 정보량 속에서 신뢰할 수 있는 PG사를 찾는 것은 생각보다 어려운 일입니다 그래서 오늘은 꼼꼼하게 믿을 수 있는 PG사! 고르는 법에 대해서 설명드리도록 하겠습니다 ㅎㅎ",
        date: "2023. 8. 25. 17:42",
        url: "https://blog.naver.com/happybridge-blog/223193420508",
        img: "insights35.jpg"
    },
    {
        type: "blog",
        title: "소프트웨어 세상에서는 없어서는 안 될 존재 API",
        content: "현재 디지털이 놀라운 속도로 발전하면서 일반적인 기술들이 대부분 자동화가 되었고 AI산업군도 점점 규모가 커지고 있는데요! 이런 기술이 발전할 수 있도록 영향을 끼친데는 API라는 매개체가 뒷받침 해주고 있다는 것을 알고 계셨나요?",
        date: "2023. 8. 25. 16:42",
        url: "https://blog.naver.com/happybridge-blog/223193360780",
        img: "insights34.png"
    },
    {
        type: "blog",
        title: "[해피브릿지 PG솔루션 컨설팅] 하반기 PG업 등록 예정인 고객사A기업",
        content: "PG업 등록을 위해서는 절차도 까다롭고 특히 해피브릿지에서만 컨설팅을 할 수 있는 클라우드 이용 PG등록같은 경우는 기업 혼자만의 힘으로는 무리가 있기때문에 이런 부분에서 해피브릿지가 최대한 도움을 드리고 있습니다!",
        date: "2023. 8. 24. 16:52",
        url: "https://blog.naver.com/happybridge-blog/223192422899",
        img: "insights33.png"
    },
    {
        type: "blog",
        title: "창업Bridge 포털사이트 오픈 예정!",
        content: "오늘은 해피브릿지 보다는 창업Bridge에 대한 설명을 해보려고 합니다 해피브릿지?창업브릿지? 여러분께 약간의 혼란을 줄 수도 있을 것 같지만 ! 오늘의 게시글을 보면 모든게 완벽히 이해가 될 것이라고 확신합니다 ㅎㅎ",
        date: "2023. 8. 24. 11:57",
        url: "https://blog.naver.com/happybridge-blog/223192139325",
        img: "insights32.jpg"
    },
    {
        type: "blog",
        title: "[여신전문금융법/전자금융거래법]법에 근거한 PG솔루션 제공, 해피브릿지",
        content: "오늘 제가 알려드릴 내용은 PG업자와 관련한 법에 대해서 쉽게 설명해드릴겁니다 정확히 간단히 말해서 PG업을 하면서 지켜야 할 법안 사항들에 대해서 알아본다고 생각하시면 될 것 같습니다! PG사를 찾고 계신분들이라면, 안전하고 신뢰할 수 있는 PG사를 선택할 때 고려해보면 되겠습니다",
        date: "2023. 7. 31. 17:36",
        url: "https://blog.naver.com/happybridge-blog/223170995322",
        img: "insights31.jpg"
    },
    {
        type: "blog",
        title: "[특허등록] 해피브릿지의 기술력, 특허로 증명하다",
        content: "오늘은 해피브릿지에서 보유하고 있는 '특허증'들을 간략하게 소개해볼까해요 ㅎㅎ 해피브릿지에서는 수많은 특허들이 등록 완료되어있습니다!",
        date: "2023. 7. 24. 17:51",
        url: "https://blog.naver.com/happybridge-blog/223164874586",
        img: "insights30.png"
    },
    {
        type: "blog",
        title: "PG사와 VAN사의 차이점은 무엇일까? 정확히 알고 가자!",
        content: "VAN사와 PG사가 유사한 일을 하는 것은 맞지만 엄연하게 차이가 존재하는데요 오늘은 명확하게 PG사와 VAN사가 어떤 역할을 하고 어떠한 차이점이 있는지 명확하게 알아볼게요!",
        date: "2023. 7. 24. 16:15",
        url: "https://blog.naver.com/happybridge-blog/223164776529",
        img: "insights29.jpg"
    },
    {
        type: "blog",
        title: "테크핀과 핀테크 비슷해보이지만, 분명한 차이가 있다!",
        content: "테크핀은 핀테크처럼 금융과 기술을 융합한 단어는 맞습니다! 하지만, 그 주체가 금융회사가 아닌 경우인데요 핀테크 같은 경우 ATM기계, 은행의 모바일뱅킹들이 해당되지만 테크핀은 카카오페이나 네이버페이처럼 우리가 일상시에 결제할 때 주로 사용하는 서비스들입니다",
        date: "2023. 7. 20. 11:32",
        url: "https://blog.naver.com/happybridge-blog/223161336696",
        img: "insights28.jpg"
    },
    {
        type: "blog",
        title: "아아! 블록체인 알고 가보자GO~!",
        content: "이번에 비트코인이 급 상승하신것을 보셨을까요? 예전에도 코인투자가 유행했지만, 이번에 비트코인이 또 급상승하면서 블록체인에 대한 관심이 집중되고 있습니다! 그렇다면 이런 블록체인 알아볼까요?",
        date: "2023. 7. 19. 14:12",
        url: "https://blog.naver.com/happybridge-blog/223160497047",
        img: "insights27.jpg"
    },
    {
        type: "blog",
        title: "마이데이터 사업 정복편",
        content: "핀테크는 대부분 들어보셨을 것 같지만 마이데이터는 조금은 생소한 단어라고 생각합니다 본인의 의료정보나 금융 정보 등을 관련 기업에 보내고 그 관련 기업은 서비스 기업에 제공하여 나에게 걸맞은 서비스나 편의성 증진을 위한 다양한 혜택을 제공하는 역할을 할 때도 활용이 가능한 데이터라고 할 수 있습니다",
        date: "2023. 7. 18. 17:42",
        url: "https://blog.naver.com/happybridge-blog/223159722381",
        img: "insights26.jpg"
    },
    {
        type: "blog",
        title: "해피브릿지, 양자암호통신 시범 구축 사업에 뛰어들다!",
        content: "오늘 가져온 내용은 아예 처음 들어본 사람들도 많을 것 같습니다! 바로 과학기술정보통신부에서 미래 핵심  기술인 양자산업 생태계 활성화를 지원하기 위해 추진했었던 '21년도 양자암호통신 기반(인프라) 시범구축사업'에 대한 내용입니다",
        date: "2023. 7. 18. 15:08",
        url: "https://blog.naver.com/happybridge-blog/223159560474",
        img: "insights25.jpg"
    },
    {
        type: "blog",
        title: "KT Cloud의 파트너사, 해피브릿지",
        content: "디지털시대로 전환이 되면서, 클라우드에 대한 수요와 관심도가 급격하게 증가하고 있습니다 현재 해피브릿지도 이런 트렌드에 맞춰 클라우드 환경에 최적화된 PG솔루션을 사용하고 있는데요 해피브릿지가 클라우드를 활용한 PG서비스를 제공할 수 있었던 건 KT Cloud의 파트너사이기 때문입니다",
        date: "2023. 7. 17. 16:40",
        url: "https://blog.naver.com/happybridge-blog/223158646994",
        img: "insights24.jpg"
    },
    {
        type: "blog",
        title: "금융과 기술을 합친, 핀테크",
        content: "금융,경제,경영을 접해보신 분들이라면 핀테크에 대해서도 한 번쯤은 들어보셨을 것이라고 생각합니다 그렇다면, 이 핀테크 도대체 어떤 의미일까요?",
        date: "2023. 7. 17. 11:25",
        url: "https://blog.naver.com/happybridge-blog/223158348372",
        img: "insights23.jpg"
    },
    {
        type: "blog",
        title: "항상 들어보기만 했던 NFC, 해피브릿지와 함께 구체적으로 알아봐요!",
        content: "다들 NFC라는 용어는 많이 들어보셨을 것 같지만 NFC가 어떤 영어의 약자인지는 모르셨을 것 같아요! Near Field Communication 라는 뜻이고 한국말로 번역하면 '근거리무선통신'입니다",
        date: "2023. 7. 14. 16:38",
        url: "https://blog.naver.com/happybridge-blog/223156178422",
        img: "insights22.jpg"
    },
    {
        type: "blog",
        title: "PG시장은 왜 발전할까?",
        content: "요즘 현금으로 결제를 하는 경우는 보기가 매우 힘들죠? 다들 카드지갑에 카드 몇개만 넣고 다니거나 아예 지갑없이 삼성페이만을 사용하시는 분들도 정말 많습니다. 이런 카드시장이 발전함에 따라 PG시장 성장성은 어떻게 변화했을까요?",
        date: "2023. 7. 12. 11:33",
        url: "https://blog.naver.com/happybridge-blog/223153829889",
        img: "insights21.jpg"
    },
    {
        type: "blog",
        title: "[창업지원편] 법인의 종류와 특징",
        content: "오늘 가져온 주제는 '법인종류'입니다 법인종류가 많아서 헷갈려하신다면 오늘 포스트가 도움이 될 것입니다 ㅎㅎ 그러면 바로 법인종류와 각 법인마다 갖는 특징을 한 번 알아볼까요~?",
        date: "2023. 7. 11. 11:49",
        url: "https://blog.naver.com/happybridge-blog/223152765089",
        img: "insights20.png"
    },
    {
        type: "blog",
        title: "[창업지원편] 복잡한 법인설립절차, 해피브릿지가 도와드립니다",
        content: "정확히 말씀드리자면 법인설립의 절차 순서와 준비사항에 대해 알아볼 것입니다! 예전과 달리 지금은 자본금의 제한이 없어져서 자본금 100원 이상이면 법인설립이 가능하고 미성년자 또는 금치산자가 아니라면 누구나 신규법인설립이 가능합니다~",
        date: "2023. 7. 10. 17:54",
        url: "https://blog.naver.com/happybridge-blog/223152105615",
        img: "insights19.jpg"
    },
    {
        type: "blog",
        title: "[창업지원편] 과세표준, 개인/법인사업자간의 세율비교(세율 어렵지 않아!)",
        content: "개인사업자와 법인사업자의 세율에 따라 세금을 산출하는 기준에 대해 비교.분석해보고 개인/법인 중 어떤 점이 사업자에게 이점이 있을지  알아보는 시간을 갖겠습니다 (우리가 알아야 할 핵심은 개인사업자와 법인사업자의 세금비율 차이니깐요..!)",
        date: "2023. 7. 10. 15:57",
        url: "https://blog.naver.com/happybridge-blog/223151977424",
        img: "insights18.jpg"
    },
    {
        type: "blog",
        title: "[창업지원편] 해피브릿지는 창업자의 든든한 지원군입니다!(사업자등록, 개인/법인 기업의 차이)",
        content: "해피브릿지 대표님께서는 IT업계에서 30년동안 종사를 해오며 한국산업개발연구원에서는 창업보육센터장으로 재직한 경력도 있으신 유능하신 분입니다! 덕분에 한국산업개발연구원에 입주해서 창업자분들에게 공간도 제공하고 세무/법무/특허 서비스 등 다양한 분야에서 도움을 드렸었습니다!",
        date: " 2023. 7. 10. 13:36",
        url: "https://blog.naver.com/happybridge-blog/223151831836",
        img: "insights17.png"
    },
    {
        type: "blog",
        title: " [Payblue서비스를 활용한 PG사업] 해피브릿지를 소개합니다!",
        content: "해피브릿지에서는 국내 KT클라우드를 기반으로 Payblue솔루션를 운영중입니다! 먼저 Payblue란 해피브릿지에서 제공하는 솔루션으로, PG업무를 통합관리하는 클라우드 매니지 서비스를 말합니다.",
        date: " 2023. 7. 7. 15:58",
        url: "https://blog.naver.com/happybridge-blog/223149614748",
        img: "insights16.jpg"
    },
    {
        type: "blog",
        title: " 전자지급결제대행 기업 해피브릿지를 소개합니다",
        content: "우리 인간이 행복해질 수 있는 방법은 타인과의 원활한 소통에서 비롯합니다. 하지만 코로나 사태가 장기화하면서 이러한 소통이 가로막히게 되었는데요. 그러한 와중에도 우리는 다른 사람들과 소통하기 위해서 디지털 기기를 통해 꾸준한 소통을 할 수 있었습니다. ",
        date: " 2023. 6. 22. 11:40",
        url: "https://blog.naver.com/happybridge-blog/223132854870",
        img: "insights15.jpg"
    },
    {
        type: "blog",
        title: " NFC스티커 무엇인가요? 의미와 활용법까지 알아보자",
        content: "다들 NFC라는 단어에 대해서는 들어보셨을 텐데요. 우리의 스마트폰에는 nfc라는 기능이 기본적으로 탑재가 되어 있습니다. 삼성 페이나 카카오 페이와 같은 페이 서비스들이 모두 nfc서비스를 활용하고 있다고 할 수 있는데요.",
        date: "2023. 6. 21. 15:30",
        url: "https://blog.naver.com/happybridge-blog/223132852032",
        img: "insights14.jpg"
    },
    {
        type: "blog",
        title: "100% 국내 클라우드서비스 하는 곳 해피브릿지",
        content: "Ai 등으로 다양한 혁신적인 기술들이 나오고 있는 와중에 다소 경직된 분야가 있다면 바로 금융 기업을 들 수 있습니다. 아무래도 개인정보와 같이 사람들에게 예민한 정보들을 공유하고 있는 만큼 그동안의 발전이 더뎠던 것은 사실인데요.",
        date: "2023. 6. 20. 10:30",
        url: "https://blog.naver.com/happybridge-blog/223132851132",
        img: "insights13.jpg"
    },
    {
        type: "blog",
        title: "케이티 클라우드 어떠한 회사일지 알아보자",
        content: "클라우드에 대한 관심도가 높아지는 것은 다들 체감하고 계실 텐데요. 우리가 알고 있는 대기업 케이티에서 케이티 클라우드를 설립하고 설립 이후 고공 성장을 하는 상황입니다.",
        date: "2023. 6. 19. 0:27",
        url: "https://blog.naver.com/happybridge-blog/223132403246",
        img: "insights12.jpg"
    },
    {
        type: "blog",
        title: "클라우드 PG 서비스 본격화, 해피브릿지와 함께",
        content: "실질적으로 도움을 받을 수 있는 서비스를 선별하는 것 혹은 도움을 받을 수 있는 좋은 서비스이지만 알려지지 않아 활용하지 못하는 것 등이 있습니다. 중에서 오늘은 획기적인 서비스이지만 알려지지 않아 활용하기 어려웠던 클라우드 PG 서비스 해피브릿지를 소개하도록 하겠습니다.",
        date: "2023. 6. 14. 11:40",
        url: "https://blog.naver.com/happybridge-blog/223126895315",
        img: "insights11.jpg"
    },
    {
        type: "blog",
        title: "애플페이 혜택  아이폰을 사용하고 있다면?",
        content: "최근 국내에서도 최신형의 아이폰을 사용하시는 분들이 점점 늘어나고 있어서 애플페이가 도입된다는 사실에 많은 아이폰유저들이 기뻐했는데요.",
        date: "2023. 6. 13. 10:10",
        url: "https://blog.naver.com/happybridge-blog/223126859672",
        img: "insights10.jpg"
    },
    {
        type: "blog",
        title: "마이데이터 사업 핀테크와 어떤 연관성이 있을까?",
        content: "기술의 발전과 디지털의 발달로 이제는 디지털 없이는 생활하기가 어려울 정도가 되었습니다. 많은 것들이 편리해지고 시간이 덜 들게 되었으며 노력하지 않아도 자동화되어 할 수 있는 것들이 많아졌는데요.",
        date: " 2023. 6. 12. 16:51",
        url: "https://blog.naver.com/happybridge-blog/223126843860",
        img: "insights9.jpg"
    },
    {
        type: "blog",
        title: "테크핀이 뭔가요? 테크핀vs핀테크",
        content: "금융의 선진화로 인해 많은 것들이 기술적으로 자동화되었고 편의성도 크게 증가했습니다. 이러한 시대적 변화에 발맞추어 다양한 단어도 생겨났는데요. 다들 핀테크에 대해서는 들어본 적이 있겠지만 테크핀은 생소해하시는 분들이 있습니다.",
        date: "2023. 6. 8. 9:00",
        url: "https://blog.naver.com/happybridge-blog/223120772512",
        img: "insights8.jpg"
    },
    {
        type: "blog",
        title: "핀테크 협회 뭐하는 곳인지 알아봐요!",
        content: "다들 경제 공부를 조금씩 했더라면 핀테크라는 단어를 들어보셨을 것입니다. 요즘 사람들이 경제에 대한 시야가 트이기 시작하면서 다양한 경제용어를 많이들 알고 계신데요, 그 중에서도 핀테크 협회와 핀테크가 무슨 단어이고 어떠한 일을 하는 곳인지에 대해서 알아보도록 하겠습니다.",
        date: "2023. 6. 6. 15:50",
        url: "https://blog.naver.com/happybridge-blog/223120764429",
        img: "insights7.jpg"
    },
    {
        type: "blog",
        title: "해피브릿지 어떤 기업일까?",
        content: "다양한 PG 서비스 기업이 있지만 인프라와 고객 맞춤의 PG 기업을 찾기는 어렵습니다. 그래서 오늘은 페이먼트 핀테크 회사인 해피브릿지에 대해서 소개해보도록 하겠습니다.",
        date: "2023. 6. 5. 14:40",
        url: "https://blog.naver.com/happybridge-blog/223120755803",
        img: "insights6.jpg"
    },
    {
        type: "blog",
        title: "pg솔루션 혁신을 선도하는 해피브릿지",
        content: "혼자서 하면 1년이 걸리는 일도 1달로 단축하여 시간과 비용을 모두 절약할 수 있는데요. 이러한 관점에서 PG솔루션의 차별화된 기술력을 가지고 있는 해피브릿지에 대해서 소개해드리려 합니다.",
        date: "2023. 6. 1. 12:30",
        url: "https://blog.naver.com/happybridge-blog/223116336497",
        img: "insights5.jpg"
    },
    {
        type: "blog",
        title: "블록체인 원리 무엇일까? 파헤쳐보자!",
        content: " 다들 블록체인에 대해서 한 번쯤은 들어보셨을 텐데요. 한동안 코인 투자가 성행하고 비트코인의 인기가 가파르게 상승하면서 블록체인에 대한 관심이 집중되었을 때가 있습니다.",
        date: "2023. 5. 31. 11:07",
        url: "https://blog.naver.com/happybridge-blog/223116319614",
        img: "insights4.jpg"
    },
    {
        type: "blog",
        title: "PG시장 성장성 궁금하다면 Click!",
        content: " 이전에는 현금 위주의 소비를 했었지만 이제는 카드만 이용이 가능한 곳이 있을 정도로 카드 소비가 크게 늘었는데요. 그러면서 PG시장 성장성 또한 달라진 부분에 대해서 알아보겠습니다.",
        date: "2023. 5. 26. 20:09",
        url: "https://blog.naver.com/happybridge-blog/223112727780",
        img: "insights3.jpg"
    },
    {
        type: "blog",
        title: "해피브릿지 금융사 클라우드 전환에 앞장서다",
        content: "AI와 같은 인공지능의 시대가 도래하며 금융 기업들이 가지는 디지털 혁신 속도가 점점 빨라지고 있습니다, 그중에서 금융 기업이 항상 경직될 수밖에 없었던 원인이었던 클라우드에 대한 규제가 풀리면서 더욱 발전에 가속도가 붙기 시작했습니다.",
        date: "2023. 5. 25. 9:50",
        url: "https://blog.naver.com/happybridge-blog/223110846836",
        img: "insights2.jpg"
    },
    {
        type: "blog",
        title: "핀테크 뜻 제대로 알고가자!",
        content: "경제에 대해서 공부를 하다 보면 핀테크라는 단어를 보신 적이 있을 것입니다, 핀테크 뜻은 금융의 Finance와 기술의 Technology가 합쳐져서 만든 단어로 어렵고 복잡한 금융을 더욱 쉽고 편리하게 관리할 수 있도록 서비스하는 것을 뜻합니다.",
        date: "2023. 5. 24. 19:47",
        url: "https://blog.naver.com/happybridge-blog/223110845992",
        img: "insights1.jpg"
    },
]




export default data;