import './App.css';
import { Routes, Route } from 'react-router-dom'
import Main from './Pages/main'
import Header from './Nav/header'
import Footer from './Nav/footer'
import About from './Pages/about'
import Business from './Pages/business'
import Contact from './Pages/contact'
import Insights from './Pages/insights'
import { useEffect, useState } from 'react';

function App() {
  const [first, setFirst] = useState(true)

  const opacity = () => {
    setTimeout(() => {
      setFirst(false);
    }, 2500);
  };

  useEffect(() => {
    opacity()
  }, [])

  return (
    <div className={first ? "App first" : "App"}>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/business" element={<Business />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/insights" element={<Insights />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
