import './css/main.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { Pagination, Autoplay, FreeMode, Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import data from './newsData';

function Main() {
    const [btn, setBtn] = useState(0)
    const [check1, setCheck1] = useState(false)
    const targetRef1 = useRef(null);
    const targetRef2 = useRef(null);
    const targetRef3 = useRef(null);

    useEffect(() => {
        AOS.init();
    })

    // const onContent1Click = () => {
    //     targetRef1.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                 if (entry.isIntersecting) {
    //                     setCheck1(true)
    //                 }
    //             });
    //         },
    //         { threshold: 0.1 }
    //     );

    //     if (targetRef1.current) {
    //         observer.observe(targetRef1.current);
    //     }
    //     return () => {
    //         observer.disconnect();
    //     };
    // }, [targetRef1]);

    // useEffect(() => {
    //     if (check1) {
    //         window.scrollTo({ top: Number(targetRef1.current.offsetTop), behavior: 'smooth' });
    //         setCheck1(false)
    //     }
    // }, [check1]);

    return (
        <div className="main">
            <div className='section1'>
                <div className='section1-img' />
                <div className='section1-1'>
                    <div className='section1-1-1' data-aos="fade-down" data-aos-duration="1500" data-aos-once="true">행복을 연결하는 기업</div>
                    <div className='section1-1-2' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="200" data-aos-once="true">해피브릿지</div>
                    <div className='section1-1-3' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="400" data-aos-once="true">
                        <Link to={`/about`}>
                            About <FontAwesomeIcon icon={faAngleRight} />
                        </Link>
                    </div>
                </div>
                <div className='section1-2' >
                    <FontAwesomeIcon icon={faChevronDown} />
                    <div className='section1-2-1'>SCROLL</div>
                </div>
            </div>
            <div className='section2' ref={targetRef1}>
                <div className='section2-box'>
                    <div className='section2-1'>
                        <div className='section2-1-1'>해피브릿지의 새 소식</div>
                    </div>
                    <div className='section2-2'>
                        <div className='section2-2-box'>
                            <div className='section2-2-img'>
                                <img src={`/img/insights/${data[0].img}`} />
                            </div>
                            <div className='section2-3' onClick={() => window.open(`${data[0].url}`)}>
                                <div className='section2-3-1'>{data[0].type}</div>
                                <div className='section2-3-2'>{data[0].title}</div>
                                <div className='section2-3-3'>{data[0].content}</div>
                                <div className='section2-3-4'> {data[0].date}</div>
                            </div>
                        </div>
                    </div>
                    <div className='section2-2'>
                        <div className='section2-2-box'>
                            <div className='section2-2-img'>
                                <img src={`/img/insights/${data[1].img}`} />
                            </div>
                            <div className='section2-3' onClick={() => window.open(`${data[1].url}`)}>
                                <div className='section2-3-1'>{data[1].type}</div>
                                <div className='section2-3-2'>{data[1].title}</div>
                                <div className='section2-3-3'>{data[1].content}</div>
                                <div className='section2-3-4'> {data[1].date}</div>
                            </div>
                        </div>
                    </div>
                    <div className='section2-2'>
                        <div className='section2-2-box'>
                            <div className='section2-2-img'>
                                <img src={`/img/insights/${data[2].img}`} />
                            </div>
                            <div className='section2-3' onClick={() => window.open(`${data[2].url}`)}>
                                <div className='section2-3-1'>{data[2].type}</div>
                                <div className='section2-3-2'>{data[2].title}</div>
                                <div className='section2-3-3'>{data[2].content}</div>
                                <div className='section2-3-4'> {data[2].date}</div>
                            </div>
                        </div>
                    </div>
                    <div className='section-2-btn'><Link to={`/insights`}>더 보기</Link></div>
                </div>

            </div>
            <div className='section3'>
                <div className='section3-1'>
                    <div className='section3-1-1'>
                        행복은 삶의 의미이며 목적이고,<br />인간 존재의 궁극적 목표이며 지향점이다.
                    </div>
                    <div className='section3-1-2'>-아리스토텔레스-</div>
                </div>
            </div>
            <div className='section4' ref={targetRef2}>
                <div className='section4-box'>
                    {
                        btn === 0
                            ?
                            <>
                                <div className='section4-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                    <div className='section4-1-1'>KT Cloud와<br />파트너 협약 체결</div>
                                    <div className='section4-1-2'>초기투자비용 약 5억원과<br />월 운영비용의 60% 절감</div>
                                </div>
                                <div className='section4-2'>
                                    <div className='section4-2-1'>대한민국 금융감독원 허가받은 전자금융회사</div>
                                    <div className='section4-2-2'>
                                        PG솔루션은 2021년 국내 최초 금감원 사용보고를 승인받은 '클라우드 전용 솔루션' 입니다.
                                    </div>
                                </div>
                            </>
                            :
                            btn === 1
                                ?
                                <>
                                    <div className='section4-1'>
                                        <div className='section4-1-1'>QKD 기반의 지갑주소 생성<br />모듈을 활용한 코인 지갑 관리 앱 개발</div>
                                        <div className='section4-1-2'>해당 모듈을 사용하는 ERC20<br />플랫폼에 코인 상장 테스트 완료</div>
                                    </div>
                                    <div className='section4-2'>
                                        <div className='section4-2-1'>NIA(한국지능정보사회진흥원)<br />양자암호통신 시범사업 진행</div>
                                        <div className='section4-2-2'>
                                            Q-Wallet은 QKD 장비를 이용하여 양자암호화 보안 통신 구간에서 사용되는 거래소의 코인지갑으로,<br />
                                            코인지갑에서 사용되는 암호화키를 QKD 장비에서 내려받아 적용됩니다.
                                        </div>
                                    </div>
                                </>
                                :
                                btn === 2
                                    ?
                                    <>
                                        <div className='section4-1'>
                                            <div className='section4-1-1'>국정원 웹메일 보안<br />심사 통과</div>
                                            <div className='section4-1-2'>SSL 암호화/PKI 기반 인증서를 적용하여,<br />국내 최고의 보안 기능을 제공</div>
                                        </div>
                                        <div className='section4-2'>
                                            <div className='section4-2-1'>성능과 보안성을 기반으로 제공</div>
                                            <div className='section4-2-2'>
                                                스마트메일러는 대법원, LF 등 대용량 고성능 메일 사용자에게 지원하는 메일 발송 서비스입니다.
                                            </div>
                                        </div>
                                    </>
                                    :
                                    btn === 3
                                        ?
                                        <>
                                            <div className='section4-1'>
                                                <div className='section4-1-1'>해피브릿지 사무실에서 즉시 발급 가능</div>
                                                <div className='section4-1-2'>해피브릿지 고객에게는<br />30% 이상의 할인율 제공</div>
                                            </div>
                                            <div className='section4-2'>
                                                <div className='section4-2-1'>KTNET과 협약을 통해 TradeSign 범용공동인증서 제공</div>
                                                <div className='section4-2-2'>
                                                    은행이나 기관에 방문 및 별도의 서류접수 등에 소요되는 시간을 줄일 수 있습니다.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='section4-1'>
                                                <div className='section4-1-1'>대법원 전자소송 문서 발송</div>
                                                <div className='section4-1-2'>서울시 외국인 종합지원시스템<br />사업에 구축 지원을 한 발송 솔루션</div>
                                            </div>
                                            <div className='section4-2'>
                                                <div className='section4-2-1'>로그 분석 및 정상 작동 기능 실시간 모니터링</div>
                                                <div className='section4-2-2'>
                                                    스마트리스트는 국가표준 암호화 알고리즘(ARIA)를 활용한 보안 메일 기능을 탑재했습니다.
                                                </div>
                                            </div>
                                        </>
                    }

                    <div className='section4-3'>
                        <div className={btn === 0 ? 'section4-3-1 active' : 'section4-3-1'} onClick={() => setBtn(0)} style={{cursor:"pointer"}}>PG 솔루션</div>
                        <div className='section4-3-1 none'></div>
                        <div className={btn === 2 ? 'section4-3-1 active' : 'section4-3-1'} onClick={() => setBtn(2)} style={{cursor:"pointer"}}>Smart Mailer</div>
                        <div className='section4-3-1 none'></div>
                        <div className={btn === 4 ? 'section4-3-1 active' : 'section4-3-1'} onClick={() => setBtn(4)} style={{cursor:"pointer"}}>Smart List</div>
                    </div>
                    <div className='section4-4'>
                        <div className={btn === 0 ? 'section4-4-1 active' : 'section4-4-1'} onClick={() => setBtn(0)}></div>
                        <div className={btn === 1 ? 'section4-4-1 active' : 'section4-4-1'} onClick={() => setBtn(1)}></div>
                        <div className={btn === 2 ? 'section4-4-1 active' : 'section4-4-1'} onClick={() => setBtn(2)}></div>
                        <div className={btn === 3 ? 'section4-4-1 active' : 'section4-4-1'} onClick={() => setBtn(3)}></div>
                        <div className={btn === 4 ? 'section4-4-1 active' : 'section4-4-1'} onClick={() => setBtn(4)}></div>
                    </div>
                    <div className='section4-3'>
                        <div className='section4-3-2 none'></div>
                        <div className={btn === 1 ? 'section4-3-2 active' : 'section4-3-2'} onClick={() => setBtn(1)} style={{cursor:"pointer"}}>Q-Wallet</div>
                        <div className='section4-3-2 none'></div>
                        <div className={btn === 3 ? 'section4-3-2 active' : 'section4-3-2'} onClick={() => setBtn(3)} style={{cursor:"pointer"}}>법인용<br />공동인증서</div>
                        <div className='section4-3-2 none'></div>
                    </div>
                </div>
            </div>
            {/* <div className='section5'>
                <div className='section5-1'>
                    <div className='section5-1-1'>
                        <div className='section5-1-1-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">압도적인 경쟁력</div>
                        <div className='section5-1-1-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">언제나 최상의 솔류션으로 고객사가 국내 금융 시장을 선도하도록 돕겠습니다.</div>
                        <img src='/img/n__12.jpg' className='section5-1-1-3' />
                    </div>
                </div>
                <div className='section5-1'>
                    <div className='section5-1-2'>
                        <div className='section5-1-1-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">고객지향의 솔루션</div>
                        <div className='section5-1-1-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">고객님의 원하시는대로 솔루션 해드립니다.</div>
                        <img src='/img/n__13.jpg' className='section5-1-1-3' />
                    </div>
                </div>
                <div className='section5-1'>
                    <div className='section5-1-1'>
                        <div className='section5-1-1-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">상생 추구</div>
                        <div className='section5-1-1-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">해피브릿지는 모두가 잘 되는것을 좋아합니다.</div>
                        <img src='/img/n__14.jpg' className='section5-1-1-3' />
                    </div>
                </div>
                <div className='section5-1'>
                    <div className='section5-1-2'>
                        <div className='section5-1-1-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">Triple S</div>
                        <div className='section5-1-1-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">Speedy, Safe, Sustainable</div>
                        <img src='/img/n__15.jpg' className='section5-1-1-3' />
                    </div>
                </div>
            </div> */}
            <div className='section8'></div>
            <div className='about-section1-6'>
                <div className='about-section1-6-2'>
                    <div className='about-section1-6-2-1'></div>
                    <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                        <div className='about-section1-6-2-3'>
                            <div className='about-section1-6-2-4'>압도적인 경쟁력</div>
                            <div className='about-section1-6-2-5'>해피브릿지는 많은 특허와 KT Colud를 이용하여 압도적인 경쟁력을 만들었습니다.</div>
                        </div>
                    </div>
                </div>
                <div className='about-section1-6-2'>
                    <div className='about-section1-6-3-1' ></div>
                    <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                        <div className='about-section1-6-2-3'>
                            <div className='about-section1-6-2-4'>고객지향의 솔루션</div>
                            <div className='about-section1-6-2-5'>해피브릿지가 제공하는 KT 금융클라우드를 도입시 최대 초기투자비용 약 5억원 및 운영비용의 60% 절감</div>
                        </div>
                    </div>
                </div>
                <div className='about-section1-6-2'>
                    <div className='about-section1-6-4-1' ></div>
                    <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                        <div className='about-section1-6-2-3'>
                            <div className='about-section1-6-2-4'>상생 추구</div>
                            <div className='about-section1-6-2-5'>해피브릿지는 다 함께 살아가는것을 좋아합니다. <br />
                                '모두가 행복한 세상' 그것을 바라보며 나아가겠습니다.</div>
                        </div>
                    </div>
                </div>
                <div className='about-section1-6-2'>
                    <div className='about-section1-6-5-1' ></div>
                    <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                        <div className='about-section1-6-2-3'>
                            <div className='about-section1-6-2-4'>Triple S<br /><span>(Speedy, Safe, Sustainable)</span></div>
                            <div className='about-section1-6-2-5'>클라우드를 활용하여 빠르고 KT 금융 클라우드 F-Cloud로 24시간 중단 없는 금융서비스로 안전하고 관리가 쉬워 지속가능합니다.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section6'>
                <div className='section6-1'>
                    <div className='section6-1-1'>"행복"을 연결하다.</div>
                    <div className='section6-1-2'>'모두가 행복한 세상' 그것을 바라보며 나아가겠습니다.</div>
                </div>
            </div>
            <div className='section7' ref={targetRef3}>
                <div className='section7-1'>
                    <div className='section7-1-1'>해피브릿지의 특허</div>
                    <div className='section7-1-2'>상표등록증, 시스템 및 기술 관련 특허, 기타 등 여러 특허를 보유하고 있습니다.</div>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={20}
                        // scrollbar={{
                        //     hide: true,
                        // }}
                        loop={true}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        speed={2000}
                        modules={[Autoplay]}
                        className="mySwiper section7-2"
                        data-aos="fade-down" data-aos-duration="1000" data-aos-once="true"
                    >
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>KIDn</span></div>
                            <img src='/img/patent/상표등록증_5건_KIDn_등_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>KIDn</span></div>
                            <img src='/img/patent/상표등록증_5건_KIDn_등_2.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>착한창업</span></div>
                            <img src='/img/patent/상표등록증_5건_KIDn_등_3.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>BIBLUE MEMBERS</span></div>
                            <img src='/img/patent/상표등록증_5건_KIDn_등_4.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>BLUE BI</span></div>
                            <img src='/img/patent/상표등록증_5건_KIDn_등_5.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>전자금융업 등록 완료 통보</span></div>
                            <img src='/img/patent/전자금융업_등록_완료_통보(주식회사_해피브릿지)_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>IoT</span></div>
                            <img src='/img/patent/특허증_2건_IoT_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>IoT</span></div>
                            <img src='/img/patent/특허증_2건_IoT_3.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>화장품용기</span></div>
                            <img src='/img/patent/특허증_2건_화장품용기_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>화장품용기</span></div>
                            <img src='/img/patent/특허증_2건_화장품용기_2.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>QR코드 또는 스마트_태그</span></div>
                            <img src='/img/patent/특허증_QR코드_또는_스마트_태그(온라인).jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>QR코드 또는 스마트 태그</span></div>
                            <img src='/img/patent/특허증_QR코드_또는_스마트_태그_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>맞춤형 봉사료</span></div>
                            <img src='/img/patent/특허증_맞춤형_봉사료_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>모의 총기 시스템</span></div>
                            <img src='/img/patent/특허증_모의_총기_시스템_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>사용환경동기화</span></div>
                            <img src='/img/patent/특허증_사용환경동기화_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>스팸메일 차단방법 및 시스템</span></div>
                            <img src='/img/patent/특허증_스팸메일_차단방법_및_시스템(소멸)_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>액션피규어</span></div>
                            <img src='/img/patent/특허증_액션피규어_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>유아용 바운서</span></div>
                            <img src='/img/patent/특허증_유아용_바운서_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>자동제어기능 플러그</span></div>
                            <img src='/img/patent/특허증_자동제어기능_플러그_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>전기기기 관리장치</span></div>
                            <img src='/img/patent/특허증_전기기기_관리장치_1.jpg' /></SwiperSlide>
                        <SwiperSlide className="section7-2-1">
                            <div className='section7-2-2'><span>주소록 관리 시스템</span></div>
                            <img src='/img/patent/특허증_주소록_관리_시스템_1.jpg' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Main;