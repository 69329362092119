import './nav.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPrint, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux'
import { checkSelectBusiness, checkSelectAbout } from '../store';

function Footer() {
    let dispatch = useDispatch();

    return (
        <div className='footer'>
            <div className='footer-box'>
                <div className='footer-box-1'>
                    <div className='footer-1'>
                        <img src="/img/logo_light.png" />
                        <div className='footer-1-1'>
                            소외된 고객의 행복을 연결하는 기업.<br />
                            기존 서비스가 채워주지 못했던 공간을 채우며,<br />
                            사람과 사람 사이의 행복을 연결합니다.
                        </div>
                        <div className='footer-1-2'>
                            {/* <img src='/img/n_3_f.jpg' />
                            <img src='/img/Rectangle_gl.jpg' />
                            <img src='/img/n___gm.jpg' />
                            <img src='/img/n_5_go.jpg' /> */}
                        </div>
                    </div>
                    <div className='footer-2'>
                        <div className='footer-2-1'>
                            <div className='footer-2-1-1'>About</div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectAbout(0))}><Link to={`/about`}>회사소개</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectAbout(1))}><Link to={`/about`}>비전</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectAbout(2))}><Link to={`/about`}>핵심가치</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectAbout(3))}><Link to={`/about`}>윤리경영</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectAbout(4))}><Link to={`/about`}>히스토리</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectAbout(5))}><Link to={`/about`}>특허</Link></div>
                        </div>
                        <div className='footer-2-1'>
                            <div className='footer-2-1-1'>Business</div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectBusiness(0))}><Link to={`/business`}>사업영역</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectBusiness(1))}><Link to={`/business`}>핀테크 개발</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectBusiness(2))}><Link to={`/business`}>웹메일 솔루션</Link></div>
                            <div className='footer-2-1-2' onClick={() => dispatch(checkSelectBusiness(3))}><Link to={`/business`}>법인용 공인인증서 발급</Link></div>
                        </div>
                        <div className='footer-2-1'>
                            <div className='footer-2-1-1'>Contact</div>
                            <div className='footer-2-1-2'><Link to={`/contact`}>오시는 길</Link></div>
                        </div>
                        <div className='footer-2-1'>
                            <div className='footer-2-1-1'>Insights</div>
                            <div className='footer-2-1-2'><Link to={`/insights`}>뉴스</Link></div>
                        </div>
                    </div>
                </div>
                <div className='footer-3'>
                    <div className='footer-3-1'>
                        <div className='footer-3-1-1'>(주)해피브릿지<span>서울 서초구 강남대로 221. 5층 514-1호</span></div>
                        <div className='footer-3-1-2'>사업자번호 :832-86-00584 <span><a href='https://bizno.net/article/8328600584' target='_blank'>사업자정보확인</a></span></div>
                    </div>
                    <div className='footer-3-2'></div>
                    <div className='footer-3-1'>
                        <div className='footer-3-3-2'>
                            <div className='footer-3-3-2-1'><FontAwesomeIcon icon={faPhone} />TEL: 1599-1873</div>
                            <div className='footer-3-3-2-1'><FontAwesomeIcon icon={faPrint} />FAX: 0504-178-2170</div>
                            <div className='footer-3-3-2-1'><FontAwesomeIcon icon={faEnvelope} />MAIL: help@hb.re.kr</div>
                        </div>
                        <div className='footer-3-3-1'>ⓒ 2023 HappyBridge. All rights reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;