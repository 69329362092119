import { Link, useLocation } from 'react-router-dom';
import './nav.css'
import AOS from 'aos';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faBars, faXmark, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux'
import { checkSelectBusiness, checkSelectAbout, checkHeaderType } from '../store';

let scrollPosition = 0

function Header() {
    const [menu, setMenu] = useState(true)
    const [current, setCurrent] = useState(0)
    const [isVisible, setIsVisible] = useState(false);
    const [width, setWidth] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [btn, setBtn] = useState(false);
    const [btn1, setBtn1] = useState(false);
    const [btn2, setBtn2] = useState(false);
    const [btn3, setBtn3] = useState(false);

    let HeaderType = useSelector((state) => { return state.HeaderType.value })

    let location = useLocation()
    let dispatch = useDispatch();

    const onMouseEnter1 = () => {
        setMenu(1)
    }

    const onMouseLeave1 = () => {
        setMenu(0)
    }
    const onMouseEnter2 = () => {
        setMenu(2)
    }

    const onMouseLeave2 = () => {
        setMenu(0)
    }
    const onMouseEnter3 = () => {
        setMenu(3)
    }

    const onMouseLeave3 = () => {
        setMenu(0)
    }
    const onMouseEnter4 = () => {
        setMenu(4)
    }

    const onMouseLeave4 = () => {
        setMenu(0)
    }

    useEffect(() => {
        let page = location.pathname
        if (page == '/about') setCurrent(1)
        else if (page == '/business') setCurrent(2)
        else if (page == '/contact') setCurrent(3)
        else if (page == '/insights') setCurrent(4)
        else setCurrent(0)
    }, [location])

    useEffect(() => {
        // 스크롤 이벤트 핸들러 등록
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsVisible(scrollY > 1200);

            if (HeaderType === 1) {
                dispatch(checkHeaderType(0))
            }
            else {
                if (scrollY < scrollPosition) {
                    dispatch(checkHeaderType(2))
                }
                else {
                    dispatch(checkHeaderType(0))
                }
            }
            if (scrollY === 0) dispatch(checkHeaderType(0))
            scrollPosition = scrollY
        };

        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1000) {
                setWidth(true)
            }
            else {
                setWidth(false)
                OffToggle()
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 화면 크기에 따른 슬라이드 수 설정

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        AOS.init();
    })

    const OffToggle = () => {
        setToggle(false)
        setBtn(false)
        setBtn1(false)
        setBtn2(false)
        setBtn3(false)
    }

    // useEffect(() => {
    //     // 이미지 경로 배열
    //     const imagePaths = ['/img/patent/상표등록증_5건_KIDn_등_1.jpg', '/img/patent/상표등록증_5건_KIDn_등_2.jpg', '/img/patent/상표등록증_5건_KIDn_등_3.jpg', '/img/patent/상표등록증_5건_KIDn_등_4.jpg', '/img/patent/상표등록증_5건_KIDn_등_5.jpg', '/img/patent/전자금융업_등록_완료_통보(주식회사_해피브릿지)_1.jpg', '/img/patent/특허증_2건_IoT_1.jpg', '/img/patent/특허증_2건_IoT_3.jpg', '/img/patent/특허증_2건_화장품용기_1.jpg', '/img/patent/특허증_2건_화장품용기_2.jpg', '/img/patent/특허증_QR코드_또는_스마트_태그(온라인).jpg', '/img/patent/특허증_QR코드_또는_스마트_태그_1.jpg', '/img/patent/특허증_맞춤형_봉사료_1.jpg', '/img/patent/특허증_모의_총기_시스템_1.jpg', '/img/patent/특허증_사용환경동기화_1.jpg', '/img/patent/특허증_스팸메일_차단방법_및_시스템(소멸)_1.jpg', '/img/patent/특허증_액션피규어_1.jpg', '/img/patent/특허증_유아용_바운서_1.jpg', '/img/patent/특허증_자동제어기능_플러그_1.jpg', '/img/patent/특허증_전기기기_관리장치_1.jpg', '/img/patent/특허증_주소록_관리_시스템_1.jpg'];

    //     // 이미지를 미리 로드
    //     imagePaths.forEach((path) => {
    //         console.log(path)
    //         const img = new Image();
    //         img.src = path;
    //     });
    // }, []);

    return (
        <div className={HeaderType === 2 ? 'header-up' : 'header-down'}>
            <div className='preload-image'></div>
            <div className='header-box'>
                <div className='header-left' onClick={() => setCurrent(0)}>
                    <Link to={`/`}>
                        <img src='/img/logo_light.png' />
                    </Link>
                </div>
                {
                    width
                        ?
                        <div className='header-ham' onClick={() => setToggle(true)}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                        :
                        <div className='header-mid'>
                            <div className={current === 1 ? 'header-mid-tit active' : 'header-mid-tit'} onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}><Link to={`/about`}>About</Link></div>
                            <div className={current === 2 ? 'header-mid-tit active' : 'header-mid-tit'} onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}><Link to={`/business`}>Business</Link></div>
                            <div className={current === 3 ? 'header-mid-tit active' : 'header-mid-tit'} onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}><Link to={`/contact`}>Contact</Link></div>
                            <div className={current === 4 ? 'header-mid-tit active' : 'header-mid-tit'} onMouseEnter={onMouseEnter4} onMouseLeave={onMouseLeave4}><Link to={`/insights`}>Insights</Link></div>
                        </div>
                }
            </div>
            {
                toggle
                    ?
                    <div className='header-nav'>
                        <div className='header-nav-box'>
                            <div className='header-nav-1'>
                                <Link to={`/`}><img src='/img/logo_light.png' onClick={() => OffToggle()} /></Link>
                                <FontAwesomeIcon icon={faXmark} onClick={() => { setToggle(false); OffToggle() }} />
                            </div>
                            <div className='header-nav-2' onClick={() => setBtn(e => !e)}>
                                <div className='header-nav-2-tit'>About</div>
                                {
                                    btn
                                        ?
                                        <FontAwesomeIcon icon={faAngleUp} />

                                        :
                                        <FontAwesomeIcon icon={faAngleDown} />

                                }
                            </div>
                            {
                                btn
                                    ?
                                    <div className='header-nav-2-txt' data-aos="fade-down" data-aos-duration="1200" data-aos-once="true">
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectAbout(0)); OffToggle() }}><Link to={`/about`}>회사소개</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectAbout(1)); OffToggle() }}><Link to={`/about`}>비전</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectAbout(2)); OffToggle() }}><Link to={`/about`}>핵심가치</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectAbout(3)); OffToggle() }}><Link to={`/about`}>윤리경영</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectAbout(4)); OffToggle() }}><Link to={`/about`}>히스토리</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectAbout(5)); OffToggle() }}><Link to={`/about`}>특허</Link></div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className='header-nav-2' onClick={() => setBtn1(e => !e)}>
                                <div className='header-nav-2-tit'>Business</div>
                                {
                                    btn1
                                        ?
                                        <FontAwesomeIcon icon={faAngleUp} />

                                        :
                                        <FontAwesomeIcon icon={faAngleDown} />

                                }
                            </div>
                            {
                                btn1
                                    ?
                                    <div className='header-nav-2-txt' data-aos="fade-down" data-aos-duration="1200" data-aos-once="true">
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectBusiness(0)); OffToggle() }}><Link to={`/business`}>사업영역</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectBusiness(1)); OffToggle() }}><Link to={`/business`}>핀테크 개발</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectBusiness(2)); OffToggle() }}><Link to={`/business`}>웹메일 솔루션</Link></div>
                                        <div className='header-nav-2-txt-1' onClick={() => { dispatch(checkSelectBusiness(3)); OffToggle() }}><Link to={`/business`}>법인용 공인인증서 발급</Link></div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className='header-nav-2' onClick={() => setBtn2(e => !e)}>
                                <div className='header-nav-2-tit'>Contact</div>
                                {
                                    btn2
                                        ?
                                        <FontAwesomeIcon icon={faAngleUp} />

                                        :
                                        <FontAwesomeIcon icon={faAngleDown} />

                                }
                            </div>
                            {
                                btn2
                                    ?
                                    <div className='header-nav-2-txt' data-aos="fade-down" data-aos-duration="1200" data-aos-once="true">
                                        <div className='header-nav-2-txt-1' onClick={() => OffToggle()}><Link to={`/contact`}>오시는 길</Link></div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className='header-nav-2' onClick={() => setBtn3(e => !e)}>
                                <div className='header-nav-2-tit'>Insights</div>
                                {
                                    btn3
                                        ?
                                        <FontAwesomeIcon icon={faAngleUp} />

                                        :
                                        <FontAwesomeIcon icon={faAngleDown} />

                                }
                            </div>
                            {
                                btn3
                                    ?
                                    <div className='header-nav-2-txt' data-aos="fade-down" data-aos-duration="1200" data-aos-once="true">
                                        <div className='header-nav-2-txt-1' onClick={() => OffToggle()}><Link to={`/insights`}>뉴스</Link></div>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    :
                    ""
            }
            {
                menu === 1
                    ?
                    <div className='header-menu' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>
                        <div className='header-menu-1'>
                            <div onClick={() => dispatch(checkSelectAbout(0))}><Link to={`/about`}>회사소개</Link></div>
                            <div onClick={() => dispatch(checkSelectAbout(1))}><Link to={`/about`}>비전</Link></div>
                            <div onClick={() => dispatch(checkSelectAbout(2))}><Link to={`/about`}>핵심가치</Link></div>
                            <div onClick={() => dispatch(checkSelectAbout(3))}><Link to={`/about`}>윤리경영</Link></div>
                            <div onClick={() => dispatch(checkSelectAbout(4))}><Link to={`/about`}>히스토리</Link></div>
                            <div onClick={() => dispatch(checkSelectAbout(5))}><Link to={`/about`}>특허</Link></div>
                        </div>
                    </div>
                    :
                    menu === 2
                        ?
                        <div className='header-menu' onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
                            <div className='header-menu-1'>
                                <div onClick={() => dispatch(checkSelectBusiness(0))}><Link to={`/business`}>사업영역</Link></div>
                                <div onClick={() => dispatch(checkSelectBusiness(1))}><Link to={`/business`}>핀테크 개발</Link></div>
                                <div onClick={() => dispatch(checkSelectBusiness(2))}><Link to={`/business`}>웹메일 솔루션</Link></div>
                                <div onClick={() => dispatch(checkSelectBusiness(3))}><Link to={`/business`}>법인용 공인인증서 발급</Link></div>
                            </div>
                        </div>
                        :
                        menu === 3
                            ?
                            <div className='header-menu' onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}>
                                <div className='header-menu-1'>
                                    <div><Link to={`/contact`}>오시는 길</Link></div>
                                </div>
                            </div>
                            :
                            menu === 4
                                ?
                                <div className='header-menu' onMouseEnter={onMouseEnter4} onMouseLeave={onMouseLeave4}>
                                    <div className='header-menu-1'>
                                        <div><Link to={`/insights`}>뉴스</Link></div>
                                    </div>
                                </div>
                                :
                                ""
            }
            {
                isVisible
                    ?
                    <div className='top-btn' onClick={() => scrollToTop()}><FontAwesomeIcon icon={faArrowUp} /></div>
                    :
                    ""
            }
        </div >
    )
}

export default Header;